<template>
  <v-container fluid>
    <PageNavigation :items="navigationItems" />

    <v-row class="py-0 my-0">
      <v-col cols="12" class="py-0 my-0">
        <v-card>
          <v-card-title
            class="white--text font-weight-bold py-4 pl-8"
            style="background: #4caf50"
          >
            {{ $vuetify.lang.t("$vuetify.department") }}

            <v-spacer />

            <v-btn
              color="white"
              outlined
              v-if="permissionCheck('modify')"
              @click="addDepartment(newObj(), $event)"
            >
              <v-icon left> mdi-account-plus </v-icon>
              {{ $vuetify.lang.t("$vuetify.add") }}
            </v-btn>
          </v-card-title>

          <v-row class="pa-4" justify="space-between">
            <v-col cols="12" lg="4" md="6" sm="6">
              <v-treeview
                :active.sync="active"
                :items="departments"
                item-children="items"
                activatable
                hoverable
                color="warning"
                transition
              >
                <template v-slot:append="{ item }">
                  <v-icon @click.stop="addDepartment(item)" color="green" left>
                    mdi-plus
                  </v-icon>

                  <v-icon @click.stop="editDepartment(item)" color="blue">
                    mdi-pencil
                  </v-icon>

                  <v-icon @click.stop="handleDelete(item)" color="red" right>
                    mdi-trash-can-outline
                  </v-icon>
                </template>
              </v-treeview>
            </v-col>

            <!-- <v-divider vertical></v-divider> -->

            <v-col
              cols="12"
              lg="8"
              md="6"
              sm="6"
              class="d-flex align-center justify-center text-center"
            >
              <v-card
                v-if="selected"
                :key="selected.id"
                flat
                :width="$vuetify.breakpoint.lgAndUp ? '350' : ''"
                elevation="2"
              >
                <v-card-text v-if="selectedLeader">
                  <v-avatar size="150">
                    <v-img
                      :src="
                        selectedLeader.avatar
                          ? imgUrl + selectedLeader.avatar
                          : blankImgUrl
                      "
                    />
                  </v-avatar>

                  <div class="mt-4 display-2 font-weight-bold">
                    {{ selectedLeader.name }}
                  </div>
                  <div class="mt-2 grey--text">
                    {{ $vuetify.lang.t("$vuetify.departmentHead") }}
                  </div>
                  <v-divider
                    class="my-4"
                    style="border: 0.5px solid lightgrey"
                  />
                  <div class="mt-2 subtitle-2">{{ selectedLeader.email }}</div>
                  <div class="mt-2 subtitle-2">{{ selectedLeader.mobile }}</div>

                  <!-- <div class="mt-4"> 
                            <span class="font-weight-medium">
                              {{ $vuetify.lang.t("$vuetify.departmentHead") + ": "}} 
                              </span> 
                              <span class="headline">
                              {{ selectedLeader.name }} 
                              </span> 
                              </div>
                          <div class="mt-4"> {{ $vuetify.lang.t("$vuetify.email") + ": " + selectedLeader.email }} </div>
                          <div class="mt-4"> {{ $vuetify.lang.t("$vuetify.phone") + ": " + selectedLeader.mobile }} </div> -->
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <DepartmentDialog
      :department="departmentObj"
      :users="users"
      :dialog.sync="dialog"
      @handleData="updateDepartment"
    />

    <DeleteDialog
      :dialogDelete.sync="deleteDialog"
      :deleteObj="this.departmentObj"
      @handleDelete="deleteDepartment"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import {
  usersList,
  departmentList,
  departmentUpsert,
  departmentDel,
} from "@/api/company";
import serverConfig from "@/utils/serverConfig";

function changeNullItems(department) {
  if (department.items == null) {
    department.items = [];
  } else {
    for (let i = 0; i < department.items.length; i++) {
      changeNullItems(department.items[i]);
    }
  }
}

function findSelected(list, id) {
  let dept = undefined;
  for (let i = 0; i < list.length; i++) {
    if (list[i].id == id) {
      return list[i];
    }
    dept = findSelected(list[i].items, id);
    if (dept != undefined) {
      break;
    }
  }
  return dept;
}

export default {
  components: {
    PageNavigation: () => import("@/components/pageNavigation"),
    DepartmentDialog: () => import("@/components/company/departmentCRUD"),
    DeleteDialog: () => import("@/components/deleteDialog"),
  },
  data() {
    return {
      dialog: false,
      deleteDialog: false,
      users: [],
      departments: [],
      active: [],
      avatar: null,
      users: [],
      navigationItems: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.company") },
        { text: this.$vuetify.lang.t("$vuetify.department") },
      ],
      departmentObj: this.newObj(),
      imgUrl: serverConfig.img_url,
      blankImgUrl: serverConfig.blank_profile_img,
    };
  },
  methods: {
    newObj() {
      return {
        id: "",
        parent: "",
        name: "",
        leader: "",
        sub_leader: "",
        accountant: [],
        order: "",
      };
    },
    updateDepartment(item) {
      departmentUpsert(item)
        .then((res) => {
          this.getData();
        })
        .catch(console.log);
    },
    addDepartment(item) {
      this.dialog = true;

      this.departmentObj = this.newObj();
      this.departmentObj.parent = item.id;
    },
    editDepartment(item) {
      if (this.permissionCheck("modify")) {
        this.dialog = true;
        this.departmentObj = item;
        if (this.departmentObj.leader)
          this.departmentObj.leader = this.departmentObj.leader.user_id;
      }
    },
    handleDelete(item) {
      this.deleteDialog = true;
      this.departmentObj = item;
    },
    async deleteAllChildrenDepartments(delDept) {
      if (delDept.items.length) {
        for (let i = 0; i < delDept.items.length; i++) {
          await this.deleteAllChildrenDepartments(delDept.items[i]);
        }
      }
      await departmentDel(delDept.id);
    },
    async deleteDepartment(item) {
      this.deleteDialog = false;
      await this.deleteAllChildrenDepartments(item);
      this.getData();
    },
    getData() {
      usersList()
        .then((data) => {
          this.users = [...data.items];
        })
        .catch((err) => console.log(err));

      departmentList()
        .then((data) => {
          this.departments = data.items;

          this.departments.forEach((element) => {
            changeNullItems(element);
          });
        })
        .catch((err) => console.log(err));
    },
  },
  computed: {
    ...mapGetters(["siteInfo"]),
    selected() {
      if (!this.active.length) return false;

      return findSelected(this.departments, this.active[0]);
    },
    selectedLeader() {
      return this.selected.leader
        ? this.users.find((u) => u.id == this.selected.leader.user_id)
        : false;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>